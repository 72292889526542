/* Fit datepicker fully inside table cells */

.react-datepicker-wrapper {
  height: 100%;
  width: 100%;
}

.react-datepicker__input-container {
  height: 100%;
}
