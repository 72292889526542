input.cell.read-only {
    width: 0px;
    height: 0px;
    padding: 0;
    margin: 0;
    position: absolute;
    outline: none;
    cursor: none;
}
input.cell.read-only:focus {
    width: 0px;
    height: 0px;
}